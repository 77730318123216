<template>
<div>
    <div v-if="isLoading">
      <CanPayLoader/>
    </div>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center"><label class="usernamelabel"> Disabled Accounts </label></div>
    </div>
    <div v-if="bank_list.length!=0">
      <div v-for="(bank, index) in bank_list" :key="index">
        <div class="row m-2">
          <div class="col-12 remove-padding">
            <span class="float-left bl-bank-name bl-f-weight">{{ index }}</span>
          </div>
        </div>
        <div
          v-if="
            typeof bank.checking != 'undefined' && bank.checking.length != 0
          "
        >
          <div class="row m-2 pl-2">
            <div class="col-12 remove-padding">
              <span class="float-left">Checking</span>
            </div>
          </div>
          <div class="row" v-for="(account, i) in bank.checking" :key="i">
            <div class="col-12">
              <div class="row bank-name-list m-2">
                <div class="col-4 align-self-center bl-f-weight">
                  <span>x{{ account.account_no }}</span>
                </div>
                <div class="col-8 align-self-center">
                  <div class="text-center">
                    <span @click="confirmEnableBank(account)" title="Enable Bank Account"> 
                      <label
                        class="enable-bank-label float-right"
                      ></label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="typeof bank.savings != 'undefined' && bank.savings.length != 0"
        >
          <div class="row m-2 pl-2">
            <div class="col-4 remove-padding">
              <span class="float-left">Savings</span>
            </div>
          </div>
          <div class="row" v-for="(account, i) in bank.savings" :key="i">
            <div class="col-12">
              <div class="row bank-name-list m-2">
                <div class="col-4 align-self-center bl-f-weight">
                  <span>x{{ account.account_no }}</span>
                </div>
                <div class="col-8 align-self-center">
                  <div class="text-center">
                    <span @click="confirmEnableBank(account)" title="Enable Bank Account"> 
                      <label
                        class="enable-bank-label float-right"
                      ></label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="one mx-auto history-div" v-else>
      <span class="success-text">No Disabled Bank Accounts Found.</span>
    </div>
    
    <b-modal
        ref="confirm-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="confirm-modal"
        centered
      >
        <div class="color">
          <div class="col-12 text-center">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="120"
              height="120"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              fill="#e14343"
            >
              <path
                d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
              />
            </svg>
          </div>
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                Do you wish to enable this bank account?
              </label>
            </div>
            <br />
            <br />
            <div class="row">
              <div class="col-12 text-center">
                <button
                  @click="hideModal('confirm-modal')"
                  class="btn btn-danger btn-md center-block cancel-btn"
                >
                  <label class="forgetpassword-ok-label">Cancel</label>
                </button>
                <button
                  @click="enableBank()"
                  class="btn btn-danger btn-md center-block tip-ok-btn"
                >
                  <label class="forgetpassword-ok-label">Confirm</label>
                </button>
              </div>
            </div>
          </div>
        </div>
    </b-modal>
    <b-modal
      ref="enable-bank-success-modal"
      hide-footer
      no-close-on-backdrop
      modal-backdrop
      hide-header
      id="enable-bank-success-modal"
      centered
    >
      <div class="color">
        <div class="col-12 text-center">
          
        </div>
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              Bank Account Enabled successfully
            </label>
          </div>
          <br />
          <br />
          <div class="row">
            <div class="col-12 text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="closeRemoveBankModal()"
              >
                <span class="purchasepower-modal-ok-label"
                  >OK</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</div>
</template>
<script>
import api from "../../api/account.js";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
export default {
  name: "DisabledBanks",
  components: {
    Loading,
    CanPayLoader
  },
  data() {
    return {
      isLoading: false,
      bank_list:[],
      account_id:""
    };
  },
  created() {
    var self = this;
    this.currentUser = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;

    if (localStorage.getItem("consumer_token") == null) {
      self.$router.push("/login");
    }else{
      self.getDisabledBankAccountList();
    }
  },
  methods: {
    getDisabledBankAccountList(){
      var self = this;
      self.isLoading = true;
      api
        .disabledAccounts()
        .then((response) => {
          self.bank_list = response.data.data;
          self.isLoading = false;
        })
        .catch(function (error) {
          self.isLoading = false;
        });
    },
    enableBank() {
      var self = this;
      self.$refs["confirm-modal"].hide();
      var request = {
        id: self.account_id,
      };
      self.isLoading = true;
      api
        .enableBankAccount(request)
        .then((response) => {
          self.$refs["enable-bank-success-modal"].show();
          self.getDisabledBankAccountList();
        })
        .catch(function (error) {
          self.isLoading = false;
        });
    },
    confirmEnableBank(account){
      var self = this;
      self.account_id = account.id;
      self.$refs["confirm-modal"].show();
    },
    closeRemoveBankModal(){
      var self = this;
      self.$refs["enable-bank-success-modal"].hide();
    },
    hideModal(modal) {
      this.$refs[modal].hide();
    },
  },
  mounted() {
    var element = document.getElementsByClassName("content-wrap");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#149240");
    }
    this.$root.$emit("loginapp", [""]);
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
  },
};
</script>

<style lang="scss">
.b-button {
  background-color: transparent;
}
#enable-bank-success-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#confirm-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
.cancel-btn {
  width: 140px;
  margin-right: 10px;
  height: 50px !important;
  background-color: #000 !important;
  border-color: #000 !important;
  display: inline-block;
  vertical-align: top;
}
</style>
